import React,  { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link} from 'react-router-dom';
import {
  Form, FormElement, Panel, AmountFormat, TableList, PrimaryButton, Toggle, TransactionRow
} from '../../../components';
import { initTimer, stopTimer } from '../../../util/initTimer';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { transactionsActions } from '../../../state/ducks/transactions';
import { TOAST_CONFIG } from '../../../config/constants';
import './style.scss';

 class DetailMovementsOperators extends Component {
   constructor(props) {
     super(props);
     this.state = {
       operation: '',
     }
   }

   componentDidMount() {
     const { location } = this.props;
     stopTimer();
     initTimer(this.props);
     if (location !== undefined && location.state.operation !== undefined) {
       this.setState({ operation: location.state.operation });
     }
   }

   render () {
     const { t } = this.props;
     const { operation } = this.state;
     if (!operation.detail) return null;
     return (
      <Fragment>
        <Panel headingText={t('detailtransaction')}>
          <TableList
            key={operation.id}
            colNumber={1}
            lengthData={operation.detail !== undefined ? operation.detail.length : 0}
            >
            <tr>
              <th>{operation.detail[0].text}</th>
              <td>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {operation.detail[0].value}
                </div>
              </td>
            </tr>

            <tr>
              <th>{`${t('typeOfTransaction')}:`}</th>
              <td>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {operation.transaction_type}
                </div>
              </td>
            </tr>

            {(operation.transaction_type === 'Crédito por comisiones'
            || operation.transaction_type=== 'Recarga de Saldo'
            || operation.transaction_type=== 'Envío de dinero'
            || operation.transaction_type=== 'Retiro de dinero'
            || operation.transaction_type=== 'Cobro con QR') ?
                ''
              :
              <tr>
                <th>{operation.detail[6].text}</th>
                <td>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {operation.detail[6].value === null ? `${t('noDetail')}` : operation.detail[6].value}
                  </div>
                </td>
              </tr>
            }

            {operation.transaction_type === 'Envío de dinero'
            || operation.transaction_type === 'Dinero Recibido' ?
              <>
                <tr>
                  <th>{operation.detail[10].text}</th>
                  <td>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {operation.detail[10].value === null ? `${t('noDetail')}` : operation.detail[10].value}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>{'CBU/CVU'}</th>
                  <td>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {operation.detail[9].value === '' ?
                        `${t('noDetail')}`
                        :
                        operation.detail[9].value
                      }
                      {" "}
                      {operation.detail[8].value === '' ?
                        ''
                        :
                        operation.detail[8].value
                      }

                    </div>
                  </td>
                </tr>
              </>
            :
              ''
            }

            <tr>
              <th>{operation.detail[1].text}</th>
              <td>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {operation.detail[1].value === null ? `${t('noRecipient')}` : operation.detail[1].value}
                </div>
              </td>
            </tr>

            {operation.transaction_type === 'Envío de dinero'
            || operation.transaction_type === 'Dinero Recibido' ?
              <tr>
                <th>{`${t('email')} / ${operation.detail[7].text}`}</th>
                <td>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {operation.detail[7].value === '' ? `${t('noDetail')}` : operation.detail[7].value}
                  </div>
                </td>
              </tr>
            :
              ''
            }

            <tr>
              <th>{operation.detail[2].text}</th>
              <td>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {operation.detail[2].value === null ? `${t('noCode')}` : operation.detail[2].value}
                </div>
              </td>
            </tr>

            {operation.transaction_type === 'Dinero recibido' ?
              <tr>
                <th>{operation.detail[3].text}</th>
                <td>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {operation.detail[3].value}
                  </div>
                </td>
              </tr>
              :
              <tr>
                <th>{operation.detail[3].text}</th>
                <td>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <AmountFormat
                      name='balance'
                      value={operation.detail[3].value}
                      currency={operation.product}
                      displayType='text'
                      allowNegative
                    />
                  </div>
                </td>
              </tr>
            }

            <tr>
              <th>{operation.detail[4].text}</th>
              <td>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {operation.detail[4].value === null ? `${t('noDetail')}` : operation.detail[4].value}
                </div>
              </td>
            </tr>

            <tr>
              <th>{operation.detail[5].text}</th>
              <td>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {operation.detail[5].value}
                </div>
              </td>
            </tr>

            {operation.transaction_type === 'Envío de dinero'
            || operation.transaction_type === 'Dinero Recibido' ?
            <tr>
              <th>{operation.detail[6].text}</th>
              <td>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {operation.detail[6].value}
                </div>
              </td>
            </tr>
            :
              ''
            }

          </TableList>
          <div className='center-table mb-20'>
            <Link to='/admin-account-list'>
              <PrimaryButton name={t('goBack')} />
            </Link>
          </div>
        </Panel>
      </Fragment>
     )
   }
 }

 export default withTranslation()(DetailMovementsOperators);
