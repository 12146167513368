import React               from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes           from 'prop-types';
import './styles.scss';

const TotalTransaction = (props) => {
  const {
    t, icon, title, content, customClass, boxClass, label, amount, operator, charge, total, chargeDescription, totalDescription, amountDescription, currency,
  } = props;
  return (
    <div className="total-transaction mb-16px">
      <article className="media">
        <div className="media-content">
          <div className="content">
            {label && <h3 className="has-text-centered">{label}</h3>}
            <div className="content-amount">
              <div className="value">
                {currency} {amount}
                <div className="information">{t(amountDescription)}</div>
              </div>
              <div className="sign">{operator}</div>
              <div className="value">
                {currency} {charge}
                <div className="information">{t(chargeDescription)}</div>
              </div>
              <div className="sign">=</div>
              <div className="value">
                {currency} {total}
                <div className="information">{t(totalDescription)}</div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
};

TotalTransaction.propTypes = {
  t: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  customClass: PropTypes.string,
  boxClass: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]).isRequired,
  label: PropTypes.string,
  amount: PropTypes.string,
  operator: PropTypes.string,
  charge: PropTypes.string,
  total: PropTypes.string,
  chargeDescription: PropTypes.string,
  totalDescription: PropTypes.string,
  amountDescription: PropTypes.string,
  currency: PropTypes.string,
};
TotalTransaction.defaultProps = {
  customClass: '',
  boxClass: '',
  label: '',
  operator: '+',
  charge: '',
  total: '',
  amount: '',
  chargeDescription: 'charge',
  totalDescription: 'Total',
  amountDescription: 'amountReceive',
  currency: '',
};
export default withTranslation()(TotalTransaction);
