import React                          from 'react';
import { Switch }                     from 'react-router-dom';
import { ConnectedRouter }            from 'connected-react-router';
import { PersistGate }                from 'redux-persist/integration/react';
import { Provider }                   from 'react-redux';
import routes, { RouteWithSubRoutes } from './config/routes';
import configureStore                 from './state/store';
import './i18n';

export const initialState = {
  auth: {
    logged: false,
    language: 'es',
    user: {
      responsible: {
        id_person_company: '',
        id: '',
      }
    },
    profile: {},
    loading: false,
    expToken: '',
    account: {
      id: 1,
      name: 'Cuenta Came',
      account_number: 'XXXXXXXXXXX',
      balance: '$ 0,00',
      product: 'AR$',
    },
  },
  transactions: {
    balanceData: {
      balance: '0.0',
      balanceReconcile: '0.0',
      currency: 'AR$',
    },
    accountDetail: {
      list: [],
      countTransactions: 0,
      dates: '',
    },
    rechargeBalancePerson: {},
    approveRechargeBalancePerson: {
      transaction_charge: null,
      transaction_code: null,
      transaction_date: null,
    },
    documentTypes: [],
    loading: false,
    error: null,
    transactionsTypes: [],
    banks: [],
    numberTransaction: '',
    amountDeposit: '0.0',
    lotClosure: {
      list: [],
      totalElements: 0
    },
    lotClosed: {},
    listLotClosed: {
      listClosed: [],
      totalCount: 0,
      pageSize: 0,
    },
    transactionsByLot: {
      list: [],
      totalElements: 0,
    },
    stateLots: [],
    lots: {
      list: [],
      totalElements: 0,
    },
    cashclosure: {},
    approveClosureCash: {},
    chargeTransfer: {
      amount: 0.00,
      charge: 0.00,
      total: 0.00,
      rulesCharge: [],
    },
    paymentQr: {
      amount: 0,
      concept: '',
    },
    extraction: {},
    voucher: {}
  },
  users: {
    list_users_operator: {
      list: [],
      totalElements: 0,
    },
    listContact: {
      list: [],
      totalElements: 0
    }
  }
};

const App = () => {
  const { store, persistor, history } = configureStore(initialState);
  // This is useful when state gets corrupted and persisted
  // persistor.purge();

  return (
    <Provider store={store}>
      <PersistGate
        loading={null}
        persistor={persistor}
      >
        <ConnectedRouter history={history}>
          <div>
            <Switch>
              {routes.map(
                (route) => <RouteWithSubRoutes key={`${route.path}`} {...route} />,
              )}
            </Switch>
          </div>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
