import axios                                 from 'axios';
import { toast }                             from 'react-toastify';
import { API_VERSION, ENDPOINTS, ENV, TIME } from './env';

import logo             from '../resources/images/auth/feedback.png';
import avatar           from '../resources/images/assets/avatar.png';
import arrowDownOutline from '../resources/images/assets/arrowDownOutline.png';
import calendar         from '../resources/images/assets/calendar.png';
import clear            from '../resources/images/assets/clear.png';

// Se usa para manejar una version, del estado de la aplicación,
// cuando se cambia, setea el storage del cliente por el estado por defecto.
export const STATE_VERSION = 0.18;

// Constante para definir la edad minima del usuario para usar la app
export const MAX_AGE = 18;

// Colores base de la aplicación
export const COLORS = {};

// Imagenes de toda la aplicacion separadas por categorias
export const IMG = {
  logo,
  avatar,
  arrowDownOutline,
  calendar,
  clear
};

export const TOAST_CONFIG = {
  SUCCESS: {
    type: toast.TYPE.SUCCESS,
    autoClose: 10000,
    position: toast.POSITION.TOP_CENTER,
  },
  INFO: {
    type: toast.TYPE.INFO,
    autoClose: 10000,
    position: toast.POSITION.TOP_CENTER,
  },
  ERROR: {
    type: toast.TYPE.ERROR,
    autoClose: 10000,
    position: toast.POSITION.TOP_CENTER,
  },
  WARNING: {
    type: toast.TYPE.WARNING,
    autoClose: 10000,
    position: toast.POSITION.TOP_CENTER,
  },
};

export const SIZE_PER_PAGE = 10;
export const TIMESESSION = TIME;

// Se genera la URL base de acuerdo a los endpoints del entorno
// Opcionalmente se puede usar versionamiento de la API
const { API } = ENDPOINTS;

export const API_URL = `${API[ENV]}/${API_VERSION}/`;

// En dado caso que se use axios
axios.defaults.baseURL = API_URL;

// Headers fetch
export const HEADERS = ({ token, expToken, contentType = null }) => {
  const headers = new Headers();
  headers.append('cache-control', 'no-cache');
  headers.append('Access-Control-Allow-Origin', '*');
  headers.append('Access-Control-Allow-Credentials', true);
  headers.append('Access-Control-Allow-Methods', 'GET,HEAD,OPTIONS,POST,PUT,DELETE');
  headers.append('Access-Control-Allow-Headers',
    'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers');
  if (contentType) {
    headers.append('Content-Type', contentType);
  }
  headers.append('Authorization', token);
  headers.append('Exptoken', expToken);
  return headers;
};


export const KEY_CAPTCHA = '6LdzwmQUAAAAANfyPA1K-G1J-OkeMS-K0ef_kfCN';
